import { Delete, MoreVert } from "@mui/icons-material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { CreatorVideo } from "../../../store/exercises/types";
import { relativeDate } from "../../../util/date";
import { BaseConfirmDialog } from "../../common/Dialog/BaseConfirmDialog";
import { YouTubeEmbed } from "../../common/YoutubeEmbed";
import {
  extractVideoIdFromUrl,
  getYoutubeThumbnailURL,
} from "../../common/YoutubeEmbed/YouTubeEmbed.utils";

interface Props {
  video: CreatorVideo;
  exerciseName: string;
  onDelete: () => void;
  onSetDefault: () => void;
  disableDelete?: boolean;
}

export const YoutubeVideoCard: React.FC<Props> = ({
  video,
  exerciseName,
  onDelete,
  onSetDefault,
  disableDelete = false,
}) => {
  const videoId = useMemo(
    () => extractVideoIdFromUrl(video.youtubeLink),
    [video.youtubeLink]
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false);
  const openDeleteConfirm = () => setIsDeleteConfirmDialogOpen(true);
  const closeDeleteConfirm = () => setIsDeleteConfirmDialogOpen(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const closeMenu = () => setAnchorEl(null);

  const closeMenuAnd = (callback: () => void) => () => {
    closeMenu();
    callback();
  };
  const closeConfirmAnd = (callback: () => void) => () => {
    closeDeleteConfirm();
    callback();
  };
  return (
    <>
      <Card sx={{ width: 345, height: "100%" }} elevation={1}>
        <CardActionArea onClick={openDialog}>
          <CardMedia
            sx={{ height: 180 }}
            image={getYoutubeThumbnailURL(videoId!)}
          />
          <CardContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                {video.isDefault && (
                  <Stack direction="row">
                    <Chip size="small" label="Default" icon={<CheckCircle />} />
                  </Stack>
                )}
                <Typography variant="body2" color="text.secondary">
                  {`Added ${
                    relativeDate(new Date(video.createdAt)) || "just now"
                  }`}
                </Typography>
              </Stack>
              <IconButton
                aria-label="video-menu-button"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  openMenu(e);
                }}
              >
                <MoreVert />
              </IconButton>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog open={isDialogOpen} fullWidth maxWidth="md" onClose={closeDialog}>
        <DialogTitle>{exerciseName}</DialogTitle>
        <DialogContent>
          <Stack gap={1}>
            <Typography variant="body2" color="text.secondary">
              {`Added ${new Date(video.createdAt).toLocaleDateString()}`}
            </Typography>
            <YouTubeEmbed videoLink={video.youtubeLink} />
            <Typography variant="body2" color="text.secondary">
              {video.youtubeLink}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog} color="neutral">
            Cancel
          </Button>
          <Button variant="contained" onClick={openDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        id="video-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "video-menu-button",
        }}
      >
        <MenuItem
          onClick={closeMenuAnd(openDeleteConfirm)}
          disabled={disableDelete}
        >
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={closeMenuAnd(onSetDefault)}
          disabled={video.isDefault}
        >
          <ListItemIcon>
            <CheckCircle fontSize="small" />
          </ListItemIcon>
          <ListItemText>Set Default</ListItemText>
        </MenuItem>
      </Menu>
      <BaseConfirmDialog
        title={"Delete video"}
        isOpen={isDeleteConfirmDialogOpen}
        onClose={closeDeleteConfirm}
        confirmButtonProps={{ color: "error" }}
        confirmText="Delete"
        onClickConfirm={closeConfirmAnd(onDelete)}
        onClickCancel={closeDeleteConfirm}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this video?
          </DialogContentText>
        </DialogContent>
      </BaseConfirmDialog>
    </>
  );
};
