import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRequest } from "../../../hooks/request/useRequest";
import { useStore } from "../../../store";
import {
  CreateExerciseVideo,
  DeleteExerciseVideo,
  GetCreatorExercise,
  UpdateCreatorExerciseNote,
} from "../../../store/exercises/actions";
import {
  selectCreateExerciseVideoAPICallState,
  selectCurrentCreatorExercise,
  selectUpdateCreatorExerciseNote,
} from "../../../store/exercises/selectors";

export const useCreatorExercisePage = () => {
  const [select, dispatch] = useStore();
  const { id: creatorExerciseIdString } = useParams();
  const creatorExerciseId = parseInt(creatorExerciseIdString!);

  const navigate = useNavigate();

  const navigateBack = () => navigate(-1);

  const fetchCreatorExerciseData = useCallback(async () => {
    creatorExerciseId && GetCreatorExercise(dispatch, creatorExerciseId);
  }, [creatorExerciseId, dispatch]);

  const { isError, isLoading, data } = select(selectCurrentCreatorExercise);
  const updateCreatorNote = select(selectUpdateCreatorExerciseNote);

  const [isAddVideoDialogOpen, setIsAddVideoDialogOpen] = useState(false);
  const openAddVideo = () => setIsAddVideoDialogOpen(true);
  const closeAddVideo = () => setIsAddVideoDialogOpen(false);

  const submitCreateExerciseVideo = async (youtubeLink: string) => {
    const promise = CreateExerciseVideo(
      dispatch,
      creatorExerciseId,
      youtubeLink
    );
    promise.then(() => fetchCreatorExerciseData());
    return promise;
  };

  const deleteExerciseVideo = async (videoId: number) => {
    const promise = DeleteExerciseVideo(dispatch, videoId);
    promise.then(() => fetchCreatorExerciseData());
    return promise;
  };

  const [SetVideoAsDefault] = useRequest(
    "post",
    "/builder/exercise/default-video/"
  );

  const setVideoAsDefault = async (videoId: number) => {
    const promise = SetVideoAsDefault(undefined, {
      data: {
        video_id: videoId,
        exercise_id: creatorExerciseId,
      },
    });
    promise.then(() => fetchCreatorExerciseData());
  };
  const createExerciseVideoCallState = select(
    selectCreateExerciseVideoAPICallState
  );

  const updateCreatorExerciseNote = async (newNote: string) => {
    creatorExerciseId &&
      (await UpdateCreatorExerciseNote(dispatch, creatorExerciseId, newNote));
  };

  useEffect(() => {
    fetchCreatorExerciseData();
  }, [fetchCreatorExerciseData]);

  return {
    isPageLoading: (!data && !isError) || isLoading,
    isPageError: isError,
    exercise: data,
    updateCreatorExerciseNote,
    refresh: fetchCreatorExerciseData,
    notes: updateCreatorNote,
    navigateBack,
    openAddVideo,
    closeAddVideo,
    isAddVideoDialogOpen,
    submitCreateExerciseVideo,
    isSubmittingNewVideo: createExerciseVideoCallState.isLoading,
    deleteExerciseVideo,
    setVideoAsDefault,
  };
};
