import React from "react";
import { AppStoreShowcase } from "../components/pages/AppStoreShowcase";
import { CreatorExercisePage } from "../components/pages/CreatorExercisePage";
import { EditWorkoutPage } from "../components/pages/EditWorkoutPage";
import { ExerciseShowroom } from "../components/pages/ExerciseShowroom";
import { MyFavourites } from "../components/pages/MyFavourites";
import { MyWorkoutPlans } from "../components/pages/MyWorkoutPlans";
import { SignIn } from "../components/pages/SignIn";
import { WorkoutPlan } from "../components/pages/WorkoutPlan";
import { WorkoutPlanBuilder } from "../components/pages/WorkoutPlanBuilder";
import { RouteConfig } from "./types";
import { WorkoutPlanDayPage } from "../components/pages/WorkoutPlanDayPage";
import { Navigate } from "react-router";
import { DashboardPage } from "../components/pages/DashboardPage";
import { Wallet } from "../components/pages/Wallet";

export const protectedRoutes: RouteConfig[] = [
  {
    path: "/favourites/workout",
    component: <MyFavourites />,
    name: "My Favourites",
    isVisible: false,
  },
  {
    path: "/favourites/workout/edit/:workoutId",
    component: <EditWorkoutPage />,
    name: "Edit Workout",
    isVisible: false,
  },
  {
    path: "/workout-plans",
    component: <MyWorkoutPlans />,
    name: "My Workout Plans",
    isVisible: true,
  },
  {
    path: "/workout-plans/:id",
    component: <WorkoutPlan />,
    name: "My Workout Plan",
    isVisible: true,
  },
  {
    path: "/workout-plans/edit/:id",
    component: <WorkoutPlanBuilder />,
    name: "Workout Plan",
    isVisible: true,
  },
  {
    path: "/workout-plans/edit/:workoutPlanId/day/:scheduleItemId",
    component: <WorkoutPlanDayPage />,
    name: "Workout Plan Day",
    isVisible: true,
  },
  {
    path: "/exercises",
    component: <ExerciseShowroom />,
    name: "Exercise Showroom",
    isVisible: true,
  },
  {
    path: "/exercises/:id",
    component: <CreatorExercisePage />,
    name: "Creator Exercise",
    isVisible: true,
  },
  {
    path: "/app-store-showcase",
    component: <AppStoreShowcase />,
    name: "App Store Showcase",
    isVisible: true,
    allowMobileDimensions: true,
  },
  {
    path: "/",
    component: <Navigate to="/workout-plans" />,
    name: "Home",
    isVisible: true,
  },
  {
    path: "/dashboard",
    component: <DashboardPage />,
    name: "Dashboard",
    isVisible: true,
  },
  {
    path: "/wallet",
    component: <Wallet />,
    name: "Wallet",
    isVisible: true,
  },
];

export const unprotectedRoutes: RouteConfig[] = [
  {
    path: "/login",
    component: <SignIn />,
    name: "Login",
    isVisible: true,
    allowMobileDimensions: true,
  },
];
