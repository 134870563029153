import {
  APICallState,
  PaginatedResource,
  Resource,
  createDefaultPaginatedResource,
  createDefaultResource,
  successLoadingErrorState,
} from "../types";

export interface CreatorVideoAPI {
  id: number;
  youtube_link: string;
  created_by: number;
  created_at: string;
  is_default_video: boolean;
}
export interface CreatorNoteAPI {
  id: number;
  content: string;
  created_by: number;
}
export interface CreatorVideo {
  id: number;
  youtubeLink: string;
  createdBy: number;
  createdAt: string;
  isDefault: boolean;
}
export interface CreatorNote {
  id: number;
  content: string;
  createdBy: number;
}

export interface CreatorExerciseAPI {
  id: number;
  name: string;
  muscle_group: string;
  default_note: string;
  weighted: boolean;
  creator_notes: CreatorNoteAPI[];
  creator_videos: CreatorVideoAPI[];
}

export interface CreatorExercise {
  id: number;
  name: string;
  muscleGroup: string;
  defaultNote: string;
  isWeighted: boolean;
  creatorNotes: CreatorNote[];
  creatorVideos: CreatorVideo[];
}

export interface ExerciseState {
  exercises: PaginatedResource<CreatorExercise>;
  currentCreatorExercise: Resource<CreatorExercise>;
  updateCreatorExerciseVideo: APICallState;
  updateCreatorExerciseNote: APICallState;
  createExerciseVideo: APICallState;
  deleteExerciseVideo: APICallState;
}

export const defaultExerciseState: ExerciseState = {
  exercises: createDefaultPaginatedResource(),
  currentCreatorExercise: createDefaultResource(),
  updateCreatorExerciseVideo: successLoadingErrorState,
  updateCreatorExerciseNote: successLoadingErrorState,
  createExerciseVideo: successLoadingErrorState,
  deleteExerciseVideo: successLoadingErrorState,
};
