import { Box, CircularProgress } from "@mui/material";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Navigate } from "react-router";
import { SideNavigation } from "../components/layout/Navigation/SideNavigation";
import { useStore } from "../store";
import { SetUserFromToken } from "../store/user/actions";
import { setBearerToken } from "../util/http/axios";
import {
  getAccessTokenFromCookie,
  getRefreshTokenFromCookie,
  refreshAccessToken,
} from "../util/http/jwt";

interface Props {}
export const ProtectedRoute: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [, dispatch] = useStore();

  const currentPath = useMemo(() => window.location.pathname, []);
  const loginQueryParameters = useMemo(() => {
    const params = new URLSearchParams();
    if (currentPath.length) {
      params.append("next", currentPath);
    }
    return params;
  }, [currentPath]);

  const checkIfAuthenticated = useCallback(async () => {
    const accessToken = getAccessTokenFromCookie();
    if (accessToken) {
      setIsAuthenticated(true);
      setBearerToken(accessToken);
      SetUserFromToken(dispatch, accessToken);
    } else {
      const refreshToken = getRefreshTokenFromCookie();
      if (refreshToken) {
        setIsRefreshingToken(true);
        const accessToken = await refreshAccessToken();
        setIsRefreshingToken(false);
        if (accessToken) {
          setBearerToken(accessToken);
          SetUserFromToken(dispatch, accessToken);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    checkIfAuthenticated();
  });

  if (isRefreshingToken) return <CircularProgress />;
  if (!isAuthenticated)
    return <Navigate to={`/login?${loginQueryParameters.toString()}`} />;
  return (
    <>
      {isRefreshingToken ? (
        <CircularProgress />
      ) : (
        <Box display="flex">
          <SideNavigation />
          {children}
        </Box>
      )}
    </>
  );
};
