import { useState } from "react";
import { isAcceptedYouTubeFormat } from "../components/common/YoutubeEmbed/YouTubeEmbed.utils";
import { useDebouncedState } from "./useDebouncedState";

export const useYouTubeVideoLinkInput = () => {
  const [isError, setIsError] = useState(false);
  const [liveValue, debouncedValue, setLiveValue] = useDebouncedState<string>(
    "",
    500
  );

  const validate = (input: string) => {
    const isValid = isAcceptedYouTubeFormat(input);
    setIsError(!isValid);
  };

  return {
    validate,
    isError,
    inputValue: liveValue,
    setInputValue: setLiveValue,
    debouncedValue,
  };
};
