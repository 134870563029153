import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  IconButtonProps,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { useErrorMessageState } from "../../../hooks/useErrorMessageState";
import { ExerciseEntry } from "../../../store/workout/types";
import { ReadOnlyCreatorVideoDialog } from "../../pages/WorkoutBuilder/ExerciseEntry/ReadOnlyCreatorVideoDialog";
import {
  ErrorVideoIconButton,
  SuccessVideoIconButton,
} from "../../pages/WorkoutBuilder/VideoIcons";
import { getEntryNotes } from "../../pages/WorkoutBuilder/utils";
import { SnackbarAlert } from "../SnackbarAlert";
import { ExerciseEntryTable } from "./ExerciseEntryTable";

interface Props {
  entry: ExerciseEntry;
}
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
export const Entry: React.FC<Props> = ({ entry }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded((x) => !x);
  };

  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [noVideoError, hideNoVideoError, showNoVideoError] =
    useErrorMessageState(false);
  const notes = getEntryNotes(entry);

  return (
    <Card variant="outlined" elevation={0}>
      <Box display={"flex"}>
        <Tooltip title={entry.exercise.muscleGroup} placement="left" arrow>
          <Box
            width={(props) => props.shape.borderRadius * 2}
            bgcolor={`muscleGroups.${entry.exercise.muscleGroup}`}
            sx={{ opacity: 0.5 }}
          />
        </Tooltip>
        <Stack flexGrow={1}>
          <CardActions disableSpacing>
            {entry.exercise.creatorVideos.length ? (
              <SuccessVideoIconButton onClick={() => setIsVideoVisible(true)} />
            ) : (
              <ErrorVideoIconButton onClick={showNoVideoError} />
            )}
            <CardHeader
              title={
                <Typography variant="h6">{entry.exercise.name}</Typography>
              }
            />
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              {entry.sets.length ? (
                <ExerciseEntryTable exerciseEntry={entry} />
              ) : (
                <Typography variant="body1">No Sets</Typography>
              )}
            </CardContent>
          </Collapse>
          <CardContent>
            <Typography variant="subtitle1" fontWeight={600} color="GrayText">
              Notes
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ fontStyle: "italic" }}
              color="GrayText"
            >
              {notes === "" ? "No Notes" : notes}
            </Typography>
          </CardContent>
        </Stack>
      </Box>
      {(!!entry.videoId || !!entry.exercise.creatorVideos.length) && (
        <ReadOnlyCreatorVideoDialog
          isVisible={isVideoVisible}
          closeModal={() => setIsVideoVisible(false)}
          name={entry.exercise.name}
          videoLink={
            entry.exercise.creatorVideos.find((video) =>
              entry.videoId !== null
                ? video.id === entry.videoId
                : video.isDefault
            )?.youtubeLink || entry.exercise.creatorVideos[0].youtubeLink
          }
        />
      )}
      <SnackbarAlert
        isOpen={noVideoError}
        close={hideNoVideoError}
        severity="warning"
        message="No video available for this exercise! Add one in Exercise Showroom"
      />
    </Card>
  );
};
