import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { YouTubeEmbed } from "../../../common/YoutubeEmbed";

interface ReadOnlyCreatorVideoDialogProps {
  isVisible: boolean;
  closeModal: () => void;
  name: string;
  videoLink: string;
}

export const ReadOnlyCreatorVideoDialog: React.FC<
  ReadOnlyCreatorVideoDialogProps
> = ({ isVisible, closeModal, name, videoLink }) => {
  return (
    <Dialog open={isVisible} fullWidth maxWidth="md">
      <DialogContent sx={{ p: 3 }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="h5" fontWeight={800}>
            {name}
          </Typography>

          <IconButton onClick={closeModal} disabled={false}>
            <Close color="action" />
          </IconButton>
        </Stack>
        <Typography variant="caption" fontWeight={800} sx={{ mb: 2 }}>
          {videoLink}
        </Typography>
        <YouTubeEmbed videoLink={videoLink} sx={{ mt: 2 }} />
      </DialogContent>
    </Dialog>
  );
};
