import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Typography, capitalize } from "@mui/material";
import { DrawerHeader } from "./SideNavigation.styles";

interface SideNavigationHeaderProps {
  isOpen: boolean;
  onClickHeaderIcon: () => void;
  firstName?: string;
  lastName?: string;
}

export const SideNavigationHeader: React.FC<SideNavigationHeaderProps> = ({
  isOpen,
  onClickHeaderIcon,
  firstName = "there",
}) => {
  return (
    <DrawerHeader
      sx={{
        justifyContent: isOpen ? "space-between" : "center",
        px: 2.5,
        minHeight: 30,
      }}
    >
      <IconButton
        onClick={onClickHeaderIcon}
        sx={{ color: "primary.contrastText" }}
      >
        {isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
      </IconButton>

      {isOpen && (
        <Box>
          <Typography variant="body1">Hey {capitalize(firstName)}!</Typography>
          <Typography variant="body2">Creator Studio</Typography>
        </Box>
      )}
    </DrawerHeader>
  );
};
