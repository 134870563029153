import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { CreatorExercise } from "../../../../store/exercises/types";
import { BaseText } from "../../../common/BaseText";
import { Table } from "../../../common/Table";
interface Props {
  exercises: CreatorExercise[];
  onSelectExercise: (exercise: CreatorExercise) => void;
}

export const ExercisesTable: React.FC<Props> = ({
  exercises,
  onSelectExercise,
}) => {
  return (
    <TableContainer component={Paper} sx={{ my: 2 }}>
      <Table sx={{ minWidth: 700 }} aria-label="exercises table">
        <TableHead>
          <TableRow>
            <TableCell width="20%">Name</TableCell>
            <TableCell width="40%">Video Link</TableCell>
            <TableCell width="40%">Default Note</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exercises.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => onSelectExercise(row)}
              hover
              sx={{ cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>
                {row.creatorVideos.length ? (
                  <BaseText variant="body1">
                    {row.creatorVideos.find((it) => it.isDefault)
                      ?.youtubeLink || row.creatorVideos[0].youtubeLink}
                  </BaseText>
                ) : (
                  <Stack direction="row" alignItems="end" gap={1}>
                    <WarningAmberRoundedIcon color="error" />
                    <BaseText variant="body2" color="error" fontWeight={700}>
                      No video
                    </BaseText>
                  </Stack>
                )}
              </TableCell>
              <TableCell>
                <BaseText variant="body1">
                  {row.creatorNotes.at(0)?.content || row.defaultNote}
                </BaseText>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
