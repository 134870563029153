import { Add, HotelOutlined } from "@mui/icons-material";
import CopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Button, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  MAX_ITEMS_IN_LIST,
  WorkoutList,
} from "../../../store/plan-builder/types";
import { BaseText } from "../../common/BaseText";
import { DraggableRest } from "./DraggableRest";
import { DraggableWorkout } from "./DraggableWorkout";
import { useDialog } from "../../common/Dialog/hooks";
import { FormConfirmDialog } from "../../common/Dialog";

interface Props {
  workoutList: WorkoutList;
  weekIndex: number;
  deleteList: () => void;
  duplicateList: () => void;
  addRest: () => void;
  removeItem: (itemIndex: number) => void;
  onCreateNewWorkout: (dayNumber: number, name: string) => Promise<void>;
  isCreatingWorkout: boolean;
  isFavouritingWorkout: boolean;
  navigateToDay: (day: number) => void;
  canEditWorkout: boolean;
  favouriteWorkout: ({
    week,
    day,
    isFavourite,
  }: {
    week: number;
    day: number;
    isFavourite: boolean;
  }) => Promise<void>;
  duplicateItem: (itemIndex: number) => void;
}

export const WorkoutListDroppable: React.FC<Props> = ({
  workoutList: { workouts, id: weekId },
  weekIndex,
  deleteList,
  duplicateList,
  addRest,
  removeItem,
  onCreateNewWorkout,
  isCreatingWorkout,
  navigateToDay,
  canEditWorkout,
  favouriteWorkout,
  isFavouritingWorkout,
  duplicateItem,
}) => {
  const [
    isCreateWorkoutDialogOpen,
    openCreateWorkoutDialog,
    closeCreateWorkoutDialog,
  ] = useDialog(false);
  const dndId = weekId;
  const dayNumber = useMemo(() => {
    return weekIndex * 7 + workouts.length + 1;
  }, [weekIndex, workouts.length]);
  return (
    <>
      <Draggable index={weekIndex} draggableId={dndId}>
        {(props) => (
          <Paper
            {...props.draggableProps}
            ref={props.innerRef}
            sx={{
              backgroundColor: "#f3f5f6",
              flexDirection: "column",
              display: "flex",
              mr: 2,
              p: 1,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
              mb={1}
              {...props.dragHandleProps}
            >
              <Box display="flex">
                <Box display="flex">
                  <DragIndicatorIcon sx={{ color: "#d5d6d7" }} />
                </Box>
                <Typography>Week {weekIndex + 1}</Typography>
              </Box>
              <Box>
                <Tooltip title="Duplicate this week">
                  <span>
                    <IconButton onClick={duplicateList}>
                      <CopyIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Delete this week">
                  <span>
                    <IconButton onClick={deleteList}>
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Box>
            <Droppable droppableId={dndId} type="workout">
              {(props) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  ref={props.innerRef}
                  {...props.droppableProps}
                >
                  {workouts.map((item, dayIndex) => {
                    return (
                      <Box
                        key={item.uuid}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        {item.workout ? (
                          <DraggableWorkout
                            index={dayIndex}
                            workout={item.workout}
                            key={item.uuid}
                            draggableId={item.uuid}
                            onDelete={() => removeItem(dayIndex)}
                            canEdit={canEditWorkout}
                            onEdit={() => navigateToDay(dayIndex + 1)}
                            onFavourite={(isFavourite: boolean) =>
                              favouriteWorkout({
                                week: weekIndex + 1,
                                day: dayIndex + 1,
                                isFavourite,
                              })
                            }
                            isFavouritingWorkoutLoading={isFavouritingWorkout}
                            onDuplicate={() => duplicateItem(dayIndex)}
                            canDuplicate={workouts.length < 7}
                            weekIndex={weekIndex}
                          />
                        ) : (
                          <DraggableRest
                            key={item.uuid}
                            index={dayIndex}
                            draggableId={item.uuid}
                            onDelete={() => removeItem(dayIndex)}
                          />
                        )}
                      </Box>
                    );
                  })}
                  {!workouts.length && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        height: 80,
                        width: 300,
                        backgroundColor: "rgba(0,0,0,0.04)",
                        borderRadius: 1,
                      }}
                    >
                      <BaseText variant="body2" color="text.secondary">
                        Drag a workout here to start
                      </BaseText>
                    </Box>
                  )}
                  {props.placeholder}
                </Box>
              )}
            </Droppable>
            <Box minWidth={300} display="flex" flexDirection="column">
              <Button
                disabled={workouts.length >= MAX_ITEMS_IN_LIST}
                onClick={addRest}
                startIcon={<HotelOutlined />}
              >
                Add Rest Day
              </Button>
              <Button
                disabled={workouts.length >= MAX_ITEMS_IN_LIST}
                onClick={openCreateWorkoutDialog}
                startIcon={<Add />}
              >
                Create new workout
              </Button>
            </Box>
          </Paper>
        )}
      </Draggable>
      <FormConfirmDialog
        inputFieldId={"new-workout-plan-workout"}
        inputFieldLabel={"Enter workout name"}
        inputFieldPlaceholder={"My New Workout"}
        onSubmitText={(name) => onCreateNewWorkout(dayNumber, name)}
        subtitle={"Don't worry, you can change this later if you want"}
        title={"First, choose a workout name"}
        isOpen={isCreateWorkoutDialogOpen}
        onClose={closeCreateWorkoutDialog}
        isLoading={isCreatingWorkout}
        onClickCancel={closeCreateWorkoutDialog}
      />
    </>
  );
};
