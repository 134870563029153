import React from "react";
import { useExerciseEntry } from "./useExerciseEntry";

import {
  CheckCircle,
  Clear,
  DragIndicator,
  ExpandMore,
  SubscriptionsRounded,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { ConfirmDialog } from "../../../common/Dialog";
import { Toggle } from "../../../common/Toggle";
import { YouTubeEmbed } from "../../../common/YoutubeEmbed";
import { getYoutubeThumbnailURLFromLink } from "../../../common/YoutubeEmbed/YouTubeEmbed.utils";
import { ExerciseEntryDataGrid } from "../ExerciseEntryDataGrid";
import { MuscleGroupChip } from "../MuscleGroupChip";
import { ErrorVideoIconButton, SuccessVideoIconButton } from "../VideoIcons";
import { EXERCISE_TYPE_ICONS, EXERCISE_TYPE_OPTIONS } from "../fixtures";
import { useInvalidEntryStyle } from "./useInvalidEntryStyle";

interface ExerciseEntryProps extends ReturnType<typeof useExerciseEntry> {}

export const ExerciseEntry: React.FC<ExerciseEntryProps> = ({
  entry,
  liveNotes,
  setLiveNotes,
  isNotesExpanded,
  setIsNotesExpanded,
  isCreatorExerciseVisible,
  openCreatorExerciseModal,
  closeCreatorExerciseModal,
  deleteEntry,
  updateExerciseType,
  deleteSet,
  updateSet,
  dragHandleProps,
  duplicateSet,
  isDeleteEntryConfirmVisible,
  openDeleteEntryConfirm,
  closeDeleteEntryConfirm,
  disableRest,
  defaultVideo,
  selectedVideo,
  setSelectedVideoIndex,
  selectedVideoIndex,
  saveWorkoutSectionsNameAndDescriptionThenNavigate,
  isExpanded,
  toggleExpanded,
  mainActionParameter,
  mainParameterCsv,
}) => {
  const { invalidEntryStyle } = useInvalidEntryStyle(entry);

  return (
    <>
      <Accordion
        elevation={1}
        expanded={isExpanded}
        sx={[
          {
            "& .MuiAccordionSummary-root": {
              flexDirection: "row",
              gap: 1,
            },
          },
          invalidEntryStyle,
        ]}
        disableGutters
      >
        <AccordionSummary
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          expandIcon={
            <IconButton onClick={toggleExpanded}>
              <ExpandMoreIcon />
            </IconButton>
          }
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              width: "100%",
            }}
            gap={1}
            {...dragHandleProps}
          >
            <Stack
              alignItems="center"
              direction="row"
              justifyContent={"space-between"}
              width="100%"
            >
              <Stack direction="row" alignItems="center">
                {dragHandleProps && <DragIndicator color="action" />}

                {entry.exercise.creatorVideos.length ? (
                  <SuccessVideoIconButton onClick={openCreatorExerciseModal} />
                ) : (
                  <ErrorVideoIconButton onClick={openCreatorExerciseModal} />
                )}

                <Typography variant="h6" sx={{ verticalAlign: "center" }}>
                  {entry.exercise.name}
                </Typography>
              </Stack>
            </Stack>
            <Stack alignItems="center" direction="row" gap={1}>
              <MuscleGroupChip muscleGroup={entry.exercise.muscleGroup} />
              {isExpanded ? (
                <>
                  <Toggle
                    options={EXERCISE_TYPE_OPTIONS}
                    value={mainActionParameter}
                    onChange={(event, newExerciseType) => {
                      newExerciseType && updateExerciseType(newExerciseType);
                    }}
                    toggleButtonProps={{ sx: { py: 1 } }}
                  />
                  <IconButton onClick={openDeleteEntryConfirm}>
                    <Clear />
                  </IconButton>
                </>
              ) : (
                <>
                  <Chip size="small" label={mainParameterCsv} />
                  {EXERCISE_TYPE_ICONS[mainActionParameter.id]}
                </>
              )}
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <ExerciseEntryDataGrid
            exerciseEntry={entry}
            disableDelete={entry.sets.length === 1}
            onDeleteSet={(setNumber) => {
              deleteSet(setNumber - 1);
            }}
            onDuplicateSet={(setNumber) => {
              duplicateSet(setNumber - 1);
            }}
            updateSet={(set) => updateSet(set)}
            disableRest={disableRest}
          />
          <Accordion
            elevation={0}
            disableGutters
            expanded={isNotesExpanded}
            onChange={() => setIsNotesExpanded((v) => !v)}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1" color="GrayText">
                Notes
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                size="small"
                multiline
                placeholder={"Add notes for this exercise"}
                fullWidth
                variant="outlined"
                value={liveNotes}
                onChange={(e) => setLiveNotes(e.target.value)}
                error={liveNotes.length > 300}
              />
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={isCreatorExerciseVisible}
        onClose={closeCreatorExerciseModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{entry.exercise.name}</DialogTitle>
        <DialogContent>
          <Stack py={1} gap={1}>
            {entry.exercise.creatorVideos.length === 0 ? (
              <DialogContentText>
                <Typography>
                  {
                    "No videos - don't forget to add one via the Exercise Showroom!"
                  }
                </Typography>
                <Button
                  variant="contained"
                  startIcon={<SubscriptionsRounded />}
                  onClick={() => {
                    saveWorkoutSectionsNameAndDescriptionThenNavigate(
                      entry.exercise
                    );
                  }}
                  sx={{ my: 1 }}
                >
                  {`Add video for ${entry.exercise.name}`}
                </Button>
                <Typography>
                  {"Refresh the page once you've added a video to see it here."}
                </Typography>
              </DialogContentText>
            ) : (
              <FormControl fullWidth>
                <InputLabel id="video-select-label">Video</InputLabel>
                <Select
                  labelId="video-select-label"
                  id="video-select"
                  value={selectedVideoIndex}
                  label="Video"
                  onChange={(e) =>
                    setSelectedVideoIndex(parseInt(e.target.value as string))
                  }
                  autoWidth={false}
                  disabled={entry.exercise.creatorVideos.length === 0}
                >
                  <MenuItem value={-1}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      flexGrow={1}
                      width={"100%"}
                      justifyContent={"space-between"}
                    >
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Box
                          component="img"
                          sx={{
                            width: 128,
                            height: 72,
                            objectFit: "cover",
                          }}
                          src={
                            defaultVideo
                              ? getYoutubeThumbnailURLFromLink(
                                  defaultVideo.youtubeLink
                                )
                              : undefined
                          }
                          borderRadius={1}
                        />
                        <Stack direction={"row"} flexGrow={1} flex={1} gap={1}>
                          <Typography noWrap textOverflow="ellipsis">
                            Always use default
                          </Typography>
                          <Chip
                            size="small"
                            label="Default"
                            icon={<CheckCircle />}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </MenuItem>
                  <Divider />
                  {entry.exercise.creatorVideos.map((video, index) => (
                    <MenuItem value={index} key={video.id}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        flexGrow={1}
                        width={"100%"}
                        justifyContent={"space-between"}
                      >
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Box
                            component="img"
                            sx={{
                              width: 128,
                              height: 72,
                              objectFit: "cover",
                            }}
                            src={getYoutubeThumbnailURLFromLink(
                              video.youtubeLink
                            )}
                            borderRadius={1}
                          />
                          <Stack
                            direction={"row"}
                            flexGrow={1}
                            flex={1}
                            gap={1}
                            alignItems={"center"}
                          >
                            <Typography noWrap textOverflow="ellipsis">
                              {video.youtubeLink}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {defaultVideo && (
              <YouTubeEmbed
                videoLink={
                  selectedVideo?.youtubeLink || defaultVideo?.youtubeLink
                }
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeCreatorExerciseModal}
            variant="outlined"
            color="neutral"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        isOpen={isDeleteEntryConfirmVisible}
        onClose={closeDeleteEntryConfirm}
        subtitle="Delete Exercise?"
        title="You're about to delete an exercise!"
        confirmText="Delete"
        onClickConfirm={deleteEntry}
        confirmButtonProps={{ color: "error" }}
        onClickCancel={closeDeleteEntryConfirm}
      />
    </>
  );
};
