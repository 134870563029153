import {
  Add,
  KeyboardBackspace,
  SubscriptionsRounded,
  WarningRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { EmptyList } from "../../common/EmptyList";
import { InlineInputEdit } from "../../common/InlineInputEdit";
import { PageHeader } from "../../common/PageHeader/PageHeader";
import { PageTemplate } from "../../common/PageTemplate";
import { MuscleGroupChip } from "../WorkoutBuilder/MuscleGroupChip";
import { AddVideoDialogForm } from "./AddVideoDialogForm";
import { CreatorExerciseLoading } from "./CreatorExerciseLoading";
import { YoutubeVideoCard } from "./YoutubeVideoCard";
import { useCreatorExercisePage } from "./useCreatorExercisePage";

interface CreatorExercisePageProps
  extends ReturnType<typeof useCreatorExercisePage> {}

export const CreatorExercisePage: React.FC<CreatorExercisePageProps> = ({
  exercise,
  updateCreatorExerciseNote,
  notes,
  navigateBack,
  closeAddVideo,
  isAddVideoDialogOpen,
  openAddVideo,
  submitCreateExerciseVideo,
  isSubmittingNewVideo,
  deleteExerciseVideo,
  setVideoAsDefault,
  isPageError,
  isPageLoading,
}) => {
  if (!exercise) {
    return <CreatorExerciseLoading />;
  }
  if (isPageLoading) {
    return <CreatorExerciseLoading />;
  }
  return (
    <>
      <PageTemplate>
        <Stack gap={1}>
          <Box mb={2}>
            <Box display="flex" alignItems="center">
              <IconButton onClick={navigateBack}>
                <KeyboardBackspace fontSize="small" />
              </IconButton>
              <Typography variant="body2" color="GrayText">
                Back
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <PageHeader title={exercise.name} />
              <MuscleGroupChip muscleGroup={exercise.muscleGroup} />
            </Box>
          </Box>
          {isPageError ? (
            <EmptyList
              onClickButton={openAddVideo}
              primaryText={"New Exercise"}
              secondaryText={`Looks like you haven't used '${exercise.name}' in a workout yet. You can still add a video for it though!`}
              icon={<WarningRounded />}
              buttonText={"Yes, add video"}
              buttonIcon={<Add />}
            />
          ) : (
            <>
              <Stack gap={1}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6">{`Videos (${exercise.creatorVideos.length})`}</Typography>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={openAddVideo}
                  >
                    Add Video
                  </Button>
                </Stack>
                {exercise.creatorVideos.length ? (
                  <Stack direction="row" gap={2} overflow="scroll" py={1}>
                    {exercise.creatorVideos.map((video, index, videos) => (
                      <React.Fragment key={video.id}>
                        <Box>
                          <YoutubeVideoCard
                            video={video}
                            exerciseName={exercise.name}
                            onDelete={() => deleteExerciseVideo(video.id)}
                            onSetDefault={() => setVideoAsDefault(video.id)}
                            disableDelete={videos.length === 1}
                          />
                        </Box>
                      </React.Fragment>
                    ))}
                  </Stack>
                ) : (
                  <EmptyList
                    onClickButton={openAddVideo}
                    primaryText={"No videos"}
                    secondaryText={
                      "You haven't uploaded any videos for this exercise yet."
                    }
                    buttonText={"Add video"}
                    icon={<SubscriptionsRounded />}
                  />
                )}
              </Stack>
              <Box
                sx={{ display: "grid", gridTemplateColumns: "1fr 5fr", gap: 2 }}
              >
                <Typography sx={{ py: 1 }} fontWeight={600}>
                  Default Note
                </Typography>
                <InlineInputEdit
                  variant="body1"
                  initialValue={
                    exercise.creatorNotes.at(0)?.content ||
                    exercise.defaultNote ||
                    ""
                  }
                  placeholderText="Add default instruction here"
                  typographyProps={{ whiteSpace: "pre-line" }}
                  textFieldProps={{
                    multiline: true,
                    label: "Enter Instruction",
                  }}
                  isInputLoading={notes.isLoading}
                  handleSubmitText={updateCreatorExerciseNote}
                  vertical
                />
              </Box>
            </>
          )}
        </Stack>
      </PageTemplate>
      <Dialog
        open={isAddVideoDialogOpen}
        onClose={isSubmittingNewVideo ? undefined : closeAddVideo}
        fullWidth
        maxWidth="md"
      >
        <AddVideoDialogForm
          close={closeAddVideo}
          isSubmitting={isSubmittingNewVideo}
          onSubmit={submitCreateExerciseVideo}
          exerciseName={exercise.name}
        />
      </Dialog>
    </>
  );
};
