import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";

interface Props {
  onClickButton: () => void;
  primaryText: string;
  secondaryText: string;
  buttonText: string;
  icon: React.ReactNode;
  buttonIcon?: React.ReactNode;
}

export const EmptyList: React.FC<Props> = ({
  onClickButton,
  primaryText,
  secondaryText,
  buttonText,
  icon,
  buttonIcon,
}) => {
  return (
    <Card variant="outlined" elevation={0}>
      <CardContent>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          p={4}
          gap={1}
        >
          <Box
            borderRadius={3}
            bgcolor="primary.xlight"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={1}
            mb={2}
            sx={({ palette }) => ({
              ".MuiSvgIcon-root": {
                color: palette.primary.main,
              },
            })}
          >
            {icon}
          </Box>
          <Box width="40%">
            <Typography
              variant="h5"
              fontWeight={700}
              align="center"
              gutterBottom
            >
              {primaryText}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              align="center"
              gutterBottom
            >
              {secondaryText}
            </Typography>
          </Box>
          <Box mt={2}>
            <Button
              variant="contained"
              startIcon={buttonIcon ?? <AddIcon />}
              onClick={onClickButton}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
