import React from "react";
import { InlineInputEdit } from "../../../common/InlineInputEdit";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { InfoOutlined } from "@mui/icons-material";
import { getSentences } from "../../../../util/string/sentence";

interface WorkoutPlanDescriptionInlineInputProps {
  description: string;
  updateWorkoutPlanDescription: (newDescription: string) => Promise<void>;
  isLoading: boolean;
}

export const WorkoutPlanDescriptionInlineInput: React.FC<
  WorkoutPlanDescriptionInlineInputProps
> = ({ description, updateWorkoutPlanDescription, isLoading }) => {
  return (
    <InlineInputEdit
      initialValue={description}
      variant="body1"
      placeholderText="Please write 1-4 sentences about this plan"
      validateText={(text: string) =>
        text.trim().length > 5 && getSentences(text).length <= 4
      }
      typographyProps={{
        color: "text.secondary",
        noWrap: true,
        sx: { p: 0.5 },
      }}
      handleSubmitText={updateWorkoutPlanDescription}
      isInputLoading={isLoading}
      textFieldProps={{
        multiline: true,
        InputProps: {
          endAdornment: (
            <Stack mr={0.5} alignItems="center" justifyContent="center">
              <Tooltip title="Shorter sentences work best">
                <InfoOutlined color="action" fontSize="small" />
              </Tooltip>
            </Stack>
          ),
        },
      }}
      minInputSizeChars={50}
    />
  );
};
