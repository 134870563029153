import {
  Edit,
  KeyboardBackspace,
  Public,
  PublicOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { PageTemplate } from "../../common/PageTemplate";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import {
  EditWorkoutPlanDescription,
  EditWorkoutPlanName,
} from "./WorkoutPlanEdit";
import { WorkoutPlanEmptySchedule } from "./WorkoutPlanEmptySchedule";
import { WorkoutPlanLoading } from "./WorkoutPlanLoading";
import { WorkoutPlanTable } from "./WorkoutPlanTable";
import { WorkoutPlanProps } from "./index";

export const WorkoutPlan: React.FC<WorkoutPlanProps> = ({
  workoutPlan,
  isShowingErrorMessage,
  hideErrorMessage,
  isLoading,
  navigateToEditWorkoutPlanPage,
  hideNoEditWarning,
  isShowingNoEditWarn,
  navigateToAllPlans,
}) => {
  return (
    <>
      {workoutPlan && !isLoading ? (
        <PageTemplate>
          <Box display="flex" alignItems="center">
            <IconButton onClick={navigateToAllPlans} data-testid="back-button">
              <KeyboardBackspace fontSize="small" />
            </IconButton>
            <Typography variant="body2" color="GrayText">
              My Workout Plans
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              display="flex"
              flexDirection={"column"}
              justifyContent="space-between"
              gap={2}
            >
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Stack>
                  <EditWorkoutPlanName workoutPlan={workoutPlan} />
                  <EditWorkoutPlanDescription workoutPlan={workoutPlan} />
                </Stack>
                <Tooltip title={"Edit this workout plan"}>
                  <span>
                    <Button
                      variant="contained"
                      startIcon={<Edit />}
                      onClick={navigateToEditWorkoutPlanPage}
                      disabled={
                        !!workoutPlan.publishedAt || !!workoutPlan.assignations
                      }
                    >
                      {"Edit"}
                    </Button>
                  </span>
                </Tooltip>
              </Stack>
              <Stack direction="row" gap={1}>
                {workoutPlan.publishedAt ? (
                  <Chip
                    sx={{
                      ml: 0,
                      mb: 2,
                    }}
                    size="small"
                    color={"success"}
                    icon={<Public />}
                    label="Published"
                  />
                ) : (
                  <Chip
                    sx={{
                      ml: 0,
                      mb: 2,
                    }}
                    size="small"
                    color={"default"}
                    icon={<PublicOff />}
                    label="Not published"
                  />
                )}
              </Stack>
            </Box>

            {workoutPlan.schedule.length ? (
              <WorkoutPlanTable workoutPlan={workoutPlan} />
            ) : (
              <WorkoutPlanEmptySchedule
                onClickCreate={navigateToEditWorkoutPlanPage}
              />
            )}
          </Box>
        </PageTemplate>
      ) : (
        <WorkoutPlanLoading />
      )}
      <SnackbarAlert
        close={hideErrorMessage}
        isOpen={isShowingErrorMessage}
        message="Something went wrong whilst getting your workout plan. Please try again later."
        severity="error"
      />
      <SnackbarAlert
        close={hideNoEditWarning}
        isOpen={isShowingNoEditWarn}
        message={
          workoutPlan?.publishedAt
            ? "We're not sure how you got there but published plans can't be edited!"
            : workoutPlan?.assignations
            ? "Plans that have been saved by your fans can't be edited!"
            : "Oops! This plan can't be edited."
        }
        severity="warning"
      />
    </>
  );
};
