import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { useYouTubeVideoLinkInput } from "../../../hooks/useYouTubeVideoLinkInput";
import { YouTubeEmbed } from "../../common/YoutubeEmbed";

interface Props {
  isSubmitting: boolean;
  onSubmit: (x: string) => Promise<void>;
  close: () => void;
  exerciseName: string;
}

export const AddVideoDialogForm: React.FC<Props> = ({
  isSubmitting,
  onSubmit,
  close,
  exerciseName,
}) => {
  const { validate, isError, setInputValue, inputValue, debouncedValue } =
    useYouTubeVideoLinkInput();

  return (
    <>
      <DialogTitle>Add Video for {exerciseName}</DialogTitle>
      <DialogContent>
        <Box py={1}>
          <Stack gap={1}>
            <TextField
              onChange={(e) => {
                setInputValue(e.target.value);
                validate(e.target.value);
              }}
              error={isError}
              helperText={isError ? "Enter a valid YouTube video link" : ""}
              value={inputValue}
              label="Add YouTube Video Link"
              autoFocus
              disabled={isSubmitting}
            />
            {!isError && !!debouncedValue.length && (
              <YouTubeEmbed videoLink={debouncedValue} />
            )}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="neutral"
          onClick={close}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => onSubmit(inputValue).then(close)}
          disabled={isSubmitting || !inputValue.length || isError}
        >
          Save
        </Button>
      </DialogActions>
      <Box p={1}>{isSubmitting && <LinearProgress color="primary" />}</Box>
    </>
  );
};
